import {eventLoader} from '@otto-ec/global-resources/event-loader';
import {o_shoppages} from './shoppages.js';

o_shoppages.cookieBannerLink = {
  init (): void {
    const footerCookieBanners = document.querySelectorAll('.sp_js_footerCookieBanner');
    for (let index = 0; index < footerCookieBanners.length; index++) {
      const footerCookieBanner = footerCookieBanners[index];
      if (!footerCookieBanner.getAttribute('data-initialized')) {
        footerCookieBanner.addEventListener('click', (event) => {
          event.preventDefault();
          window.o_cookieBanner.resurfaceBanner();
          o_shoppages.o_tracking.sendEventToTrackingServer({ot_Origin: 'footer_CookieBanner'});
        });
      }
      footerCookieBanner.setAttribute('data-initialized', 'true');
    }
  }
};

eventLoader.onAllPreloadScriptsLoaded(100, () => {
  o_shoppages.cookieBannerLink.init();
});
