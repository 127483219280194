const { cookie } = window.o_util ?? {};
const { exists, get, remove, set } = cookie ?? {};
export {
  cookie,
  exists,
  get,
  remove,
  set
};
/*                               */
