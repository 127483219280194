import {cookie} from '@otto-ec/global-resources/cookie';
import {eventLoader} from '@otto-ec/global-resources/event-loader';
import {o_shoppages} from './shoppages.js';

function getParameterSeparator (urlParams: string|undefined|null): string {
  if (typeof urlParams === 'undefined' && urlParams !== null) {
    return '?';
  }
  return '&';
}

o_shoppages.countryLayer = {

  resolveCountryLayer (url: string) {
    const countryLayerCookie: string = cookie.get('countryLayer');
    if (typeof countryLayerCookie === 'undefined' || countryLayerCookie !== '1') {
      cookie.set('countryLayer', '1', {path: '/'});
      const countryLayer = new window.o_global.pali.sheet({url: url});
      countryLayer.open();
    }
  },

  displayCountryLayer (url: string) {
    eventLoader.onLoad(110, () => {
      this.resolveCountryLayer(url);
    });
  },

  getDocumentReferrer () :string {
    return document.referrer;
  },

  getDocumentHref () :string {
    return document.location.href;
  },

  assignShopPathToATLink (linkButtonId: string): void {
    const link: HTMLElement|null = document.getElementById(linkButtonId);
    if (link !== null) {
      let referrerParam = '';
      const documentReferrer: string = this.getDocumentReferrer();
      if (documentReferrer !== null && documentReferrer !== '') {
        referrerParam = `&ovdredchannel=${documentReferrer}`;
      }
      /*                  */
      /*                                                                                                  */
      /*                                         */
      const hrefSeparator = /^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
      const documentHrefParts: string[] = this.getDocumentHref().split(hrefSeparator);
      const linkHrefParts: string[]|undefined = link.getAttribute('href')?.split(hrefSeparator);
      const extendedLinkHref = `${link.getAttribute('href')}${getParameterSeparator(
        linkHrefParts?.[6])}ovdredurl=${documentHrefParts[5].replace(/\/$/, '')}${referrerParam}`;
      link.setAttribute('href', extendedLinkHref);
    }
  }
};
