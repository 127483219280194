import { otto } from "./nexus.mjs";
const { dom } = window.o_util ?? {};
const {
  stringToDocumentFragment,
  getBodyElement,
  getFirstElementByTagName,
  getHeadElement
} = dom ?? {};
const { onLoad, onReady } = otto.dom ?? {};
export {
  dom,
  getBodyElement,
  getFirstElementByTagName,
  getHeadElement,
  onLoad,
  onReady,
  stringToDocumentFragment
};
/*                            */
